import { Button } from "react-bootstrap";
import "./styles.scss";

const SubmitFormButton = ({ formInfo, text, variant }) => {
  const { name, email, company, message, partnered, interestArea, tandcs } =
    formInfo;
  const mailToEmail =
    interestArea === "John Lewis"
      ? "anna.khan@johnlewis.co.uk"
      : "laura.wakeling@waitrose.co.uk";
  const subject = "New Web Enquiry";
  const body = `Name: ${name}%0D%0AEmail: ${email}%0D%0ACompany: ${company}%0D%0AHave we partnered before? ${partnered}%0D%0A%0D%0A%0D%0A${message}`;
  return (
    <Button disabled={!tandcs} variant={variant}>
      <a
        className="button-link"
        href={`mailto:${mailToEmail}?subject=${subject}&body=${body}`}
      >
        {text}
      </a>
    </Button>
  );
};

export default SubmitFormButton;
