import { Container } from "react-bootstrap";

const DataProcessingAgreementHidden = () => {
  return (
    <Container className="w-100 main-container">
      <p className="body-l body-text mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel
        venenatis felis. Sed tincidunt ultricies semper. In hac habitasse platea
        dictumst. Duis ligula nisl, faucibus vitae maximus vel, malesuada tempus
        turpis. Nulla vel dignissim augue. In eros felis, sagittis vitae
        vulputate vel, venenatis vitae nisi. Aliquam erat volutpat.
      </p>

      <p className="body-l body-text mb-4">
        Pellentesque fringilla eget ipsum id posuere. Praesent semper facilisis
        eleifend. Duis pharetra faucibus lectus, sit amet fringilla dui
        pellentesque id. Vivamus eleifend magna vitae lorem tempor auctor.
        Aenean eget lacus id ligula lobortis porttitor at aliquet ex. Quisque
        facilisis interdum felis at posuere. Nam mattis eros eu nibh cursus, sit
        amet laoreet libero imperdiet. Nulla facilisi. Aenean posuere lacus a
        libero condimentum, eu suscipit felis suscipit. Nunc aliquet, mauris sit
        amet dictum sollicitudin, urna velit facilisis est, vitae condimentum
        eros purus sit amet neque. Nam posuere volutpat felis ut posuere.
        Phasellus lacus velit, bibendum ut interdum quis, rhoncus ut ante. Nulla
        molestie orci nec orci scelerisque blandit. Mauris semper elit eget arcu
        porttitor, vel pellentesque magna consectetur. Proin eu eros
        sollicitudin, placerat nunc malesuada, semper erat. In hendrerit nisl
        nibh, porta pretium purus fringilla pellentesque.
      </p>

      <p className="body-l body-text mb-4">
        Suspendisse suscipit porttitor lectus, eu viverra nibh dapibus ut.
        Aenean ornare massa quis venenatis feugiat. Nulla laoreet urna lacus, at
        egestas turpis facilisis id. Curabitur venenatis lobortis viverra. Nunc
        vulputate augue ac erat facilisis congue. Duis non sodales ante.
        Vestibulum vel velit nibh. Nunc sollicitudin felis at sodales lacinia.
        Cras eleifend pretium nisi in lobortis. Proin vitae quam vulputate,
        sodales felis posuere, porttitor est. Cras egestas ornare quam. Proin at
        enim feugiat, euismod enim vel, hendrerit ligula. Nulla ultricies sed
        ante sed interdum.
      </p>
    </Container>
  );
};

export default DataProcessingAgreementHidden;
